<template>
  <div>
    <div>
      <Panel module="Movimentação" title="Pesquisa Retorno" :showFilter="false" :showSearch="false"
        :showButtons="false">
        <div slot="content-main">
          <br />
          <div class="main">
            <SearchList />
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import SearchList from '../../../components/modules/operational/SearchList.vue'

import { mapMutations } from "vuex";

export default {
  name: "OperationlSearchView",
  components: {
    Panel,
    SearchList
  },

  mounted() {
    this.removeLoading(["panel", "btnPdvSearch"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading", "btnPdvSearch"]),
  },
};
</script>
